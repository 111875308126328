<template>
  <div class="row">
    <span class="localstorage-existing-data-notice" v-if="DataExistsInLocalStorage && surveyId===null">Click <a href="javascript:;" @click="fillLocalStorageBaiscDetails">here</a> to restore unsaved changes!</span>
    <div class="col-xs-12 col-sm-6">
      <select name="surveyType" class="input" v-model="basicSurveyDetails.type">
        <option value="">Select PICKL Task Type</option>
        <option value="PICKL">Geo-Located Task</option>
        <option value="MEDIA">Go-Anywhere Task - Part 1 (Media Page)</option>
        <option value="NON_GEO">Go-Anywhere Task - Part 2 (Create Task)</option>
      </select>
    </div>
    <div class="col-xs-12 col-sm-6">
      <input
        v-validate="'required'"
        type="text"
        placeholder="Insight Name"
        name="surveyName"
        v-model="basicSurveyDetails.title"
        maxlength="23"
        class="input"
        aria-required="true"
        aria-invalid="false"
        :class="{'input': true, 'is-danger': errors.has('add-survey.surveyName') }"
      />
    </div>
    <div class="col-xs-12 col-sm-12">
      <textarea
        name="surveyDescription"
        v-validate="'required'"
        maxlength="60"
        placeholder="Description - 60 Characters Max"
        rows="2"
        class="input textarea"
        v-model="basicSurveyDetails.description"
        :class="{'input': true, 'is-danger': errors.has('add-survey.surveyDescription') }"
      ></textarea>
    </div>
  </div>
</template>
<script>
export default {
  props: ['basicSurveyDetails', 'surveyId'],
  data () {
    return {
      DataExistsInLocalStorage: false
    }
  },
  methods: {
    checkLocalStorageForBasicDetails () {
      let localStorageBasicDetails = localStorage.getItem('SurveyBasicDetails')
      if (localStorageBasicDetails !== null) {
        this.DataExistsInLocalStorage = true
      }
    },
    fillLocalStorageBaiscDetails () {
      let data = JSON.parse(localStorage.getItem('SurveyBasicDetails'))
      this.$emit('basicDetailsUpdated', data)
      this.DataExistsInLocalStorage = false
    }

  },
  mounted () {
    this.checkLocalStorageForBasicDetails()
    if (Boolean(this.$route.query.filldraft) === true) {
      this.fillLocalStorageBaiscDetails()
    }
  }
}
</script>
<style scoped>
.localstorage-existing-data-notice {
    text-align: center;
    display: block;
    color: black;
    font-size: 16px;
    margin-bottom: 10px;
    transition: opacity cubic-bezier(0, 0.52, 1, 1) 1s
  }
  select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
</style>
