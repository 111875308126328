


































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ReviewSurvey extends Vue {
  @Prop() basicSurveyDetails!: object;
  @Prop() surveyQuestionAnswers!: object;

  getAnwerType (value:String) {
    if (value === 'SINGLE_ANSWER') {
      return 'Single Answer'
    } else if (value === 'MULTIPLE_ANSWER') {
      return 'Multiple Answer'
    } else {
      return 'Textual Answer'
    }
  }
}
