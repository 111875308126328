var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.DataExistsInLocalStorage && _vm.surveyId === null
      ? _c("span", { staticClass: "localstorage-existing-data-notice" }, [
          _vm._v("Click "),
          _c(
            "a",
            {
              attrs: { href: "javascript:;" },
              on: { click: _vm.fillLocalStorageBaiscDetails }
            },
            [_vm._v("here")]
          ),
          _vm._v(" to restore unsaved changes!")
        ])
      : _vm._e(),
    _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.basicSurveyDetails.type,
              expression: "basicSurveyDetails.type"
            }
          ],
          staticClass: "input",
          attrs: { name: "surveyType" },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.basicSurveyDetails,
                "type",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            }
          }
        },
        [
          _c("option", { attrs: { value: "" } }, [
            _vm._v("Select PICKL Task Type")
          ]),
          _c("option", { attrs: { value: "PICKL" } }, [
            _vm._v("Geo-Located Task")
          ]),
          _c("option", { attrs: { value: "MEDIA" } }, [
            _vm._v("Go-Anywhere Task - Part 1 (Media Page)")
          ]),
          _c("option", { attrs: { value: "NON_GEO" } }, [
            _vm._v("Go-Anywhere Task - Part 2 (Create Task)")
          ])
        ]
      )
    ]),
    _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.basicSurveyDetails.title,
            expression: "basicSurveyDetails.title"
          }
        ],
        staticClass: "input",
        class: {
          input: true,
          "is-danger": _vm.errors.has("add-survey.surveyName")
        },
        attrs: {
          type: "text",
          placeholder: "Insight Name",
          name: "surveyName",
          maxlength: "23",
          "aria-required": "true",
          "aria-invalid": "false"
        },
        domProps: { value: _vm.basicSurveyDetails.title },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.basicSurveyDetails, "title", $event.target.value)
          }
        }
      })
    ]),
    _c("div", { staticClass: "col-xs-12 col-sm-12" }, [
      _c("textarea", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.basicSurveyDetails.description,
            expression: "basicSurveyDetails.description"
          }
        ],
        staticClass: "input textarea",
        class: {
          input: true,
          "is-danger": _vm.errors.has("add-survey.surveyDescription")
        },
        attrs: {
          name: "surveyDescription",
          maxlength: "60",
          placeholder: "Description - 60 Characters Max",
          rows: "2"
        },
        domProps: { value: _vm.basicSurveyDetails.description },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.basicSurveyDetails, "description", $event.target.value)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }