var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.basicSurveyDetails.title != ""
      ? _c("div", { staticClass: "single-quesion-box" }, [
          _c("h3", {
            domProps: { innerHTML: _vm._s(_vm.basicSurveyDetails.title) }
          }),
          _c("p", [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(_vm.basicSurveyDetails.description)
              }
            })
          ])
        ])
      : _vm._e(),
    _vm.surveyQuestionAnswers.length > 0
      ? _c(
          "div",
          _vm._l(_vm.surveyQuestionAnswers, function(question, qi) {
            return _c(
              "div",
              { key: qi, staticClass: "row single-quesion-box" },
              [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("strong", [_vm._v("Question " + _vm._s(qi + 1) + ": ")]),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(question.question) }
                  })
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("strong", [_vm._v("Answer Type: ")]),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.getAnwerType(question.questionType))
                    }
                  })
                ]),
                _c("br"),
                question.questionType != "TEXT"
                  ? _c(
                      "ul",
                      _vm._l(question.answers, function(answer, ai) {
                        return _c("li", {
                          key: ai,
                          domProps: { innerHTML: _vm._s(answer.answerOption) }
                        })
                      }),
                      0
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }